@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@font-face {
    font-family: 'Avenir LT Std';
    src: url('../fonts/AvenirLTStd-Roman.woff2') format('woff2'),
    url('../fonts/AvenirLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Nexa Script';
    src: url('../fonts/NexaScript-Regular.woff2') format('woff2'),
    url('../fonts/NexaScript-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


* {
    -webkit-appearance: none;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-style: normal;
    margin: 0;
    outline: 0;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    text-decoration: none
}



body {
    background-color: #fff;
    color: #b5159e;
    font-family: 'Bebas Neue', cursive;
    font-size: 28px;
    line-height: 38px;
    overflow-x: hidden
}

body.init {
    opacity: 0
}

h1 {
    font-size: 76px;
    font-weight: 900;
    line-height: 81px;
    text-transform: uppercase;
	font-family: 'Bebas Neue', cursive;
}

h2,
h3 {
    font-family: 'Bebas Neue', cursive;
}

b,
b *,
h2,
h3,
strong,
strong * {
    font-weight: 700
}

h2 {
    font-size: 49px;
    line-height: 54px;
    margin-bottom: 54px
}

h3 {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 25px
}

p {
    margin-bottom: 38px
}

a {
    color: inherit
}

ul {
    list-style-position: inside
}

input[type=text],
input[type=email],
textarea {
    background: 0 0;
    border-style: solid;
    border-width: 0 0 1px;
    color: inherit;
    font-family: inherit;
    font-size: 19px;
    height: 62px;
    margin-bottom: 40px;
    width: 100%
}

::-webkit-input-placeholder {
    color: inherit;
    text-transform: uppercase
}

:-moz-placeholder {
    color: inherit;
    text-transform: uppercase
}

::-moz-placeholder {
    color: inherit;
    text-transform: uppercase
}

:-ms-input-placeholder {
    color: inherit;
    text-transform: uppercase
}


#characteristics h2,
.page-header .main-menu {
    text-transform: uppercase
}

textarea {
    height: 160px;
    padding-top: 22px;
    resize: none;
    textarea: 29px
}

i,
i * {
    font-style: italic
}
p{
font-family: 'Bebas Neue', cursive;	
}
#menu-main li a, #characteristics h2{
	font-family: 'Bebas Neue', cursive;
}
#menu-secondary li a{
	font-family: 'Bebas Neue', cursive;
}
.intro h2{
	font-family: 'Bebas Neue', cursive;
}
.page-header .main-menu a {
    font-weight: 400
}

body,
html {
    height: 100%
}

a,
u,
u * {
    text-decoration: underline
}

#characteristics ul,
#flavours ul,
#retailers ul,
.App .screen.banners .nav,
.App .screen.instagram ul,
.page-footer ul,
.page-header ul,
.page-template-page-faq .questions {
    list-style-type: none
}

#flavours .item a,
.btn,
.App .screen.instagram .hover-card,
.nolink,
body:not(.page-template-page-newsletter) .page-header nav a {
    text-decoration: none
}

.clearfix:after {
    clear: both;
    content: "";
    display: table
}

.nolink {
    cursor: default
}
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: #b5159e;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #b5159e;
    cursor: pointer;
  }

.btn {
    background-color: #b5159e;
    border-color: #b5159e;
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 28px;
    font-weight: 900;
    height: 58px;
    line-height: 58px;
    padding: 0 50px;
    text-align: center;
    text-transform: uppercase;
    transition: all .5s;
    font-family:'Bebas Neue', cursive;
}

.btn.white,
.btn:not(.disabled):hover {
    background-color: #fff;
    color: #b5159e
}

.btn.white {
    border-color: #fff
}

.btn:not(.disabled).white:hover {
    background-color: #b5159e;
    color: #fff
}

.disabled {
    cursor: default !important;
    opacity: .5 !important
}

.invalid {
    border-color: #f00000 !important
}

.output {
    color: #f00000;
    display: none;
    font-size: 19px;
    line-height: 29px
}

.output.success {
    color: inherit
}

.page-header {
    background-color: #b5159e;
    background-position: center center;
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    padding: 25px 0 25px;
    position: relative;
    text-align: center;
    margin:0;
}

.page-header .intro,
.page-header h1 {
   /* position: relative;
    z-index: 1*/
}

.page-header .intro {
    margin-top: 54px
}

.page-header .logo img {
    left: 0;
    position: absolute;
    width: 10%;
    top: 60px
}

.page-header .menu-opener {
    background-color: #b5159e;
    border: 2px solid #fff;
    border-radius: 27.5px;
    cursor: pointer;
    display: block;
    font-size: 23px;
    line-height: 23px;
    padding-top: 11px;
    position: fixed;
    height: 50px;
    right: 6%;
    top: 60px;
    width: 50px;
    z-index: 20
}

/*.page-header .menu-opener.blue {
    background-color: transparent;
    border-color: #b5159e;
    color: #b5159e
}*/

.page-header{
    height:155px;
}

.App .page-content{
    padding:0;
}

.page-header .menu-opener.white {
    background-color: transparent;
    border-color: #fff;
    color: #fff
}

body:not(.page-template-page-newsletter) .page-header nav {
    background-color: #fff;
    border-left: 1px solid #b5159e;
    color: #b5159e;
    height: 100%;
    min-width: 320px;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 25%;
    z-index: 30
}

body:not(.page-template-page-newsletter) .page-header nav:not(.opened) {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0)
}

.page-header .menu-closer {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 7%;
    top: 20px;
}

.page-header .main-menu {
    font-size: 35px;
    font-weight: 400;
    margin-top: 20px;
    padding: 0 30px
}

.page-header .main-menu li {
    margin-bottom: 30px
}

.page-header .secondary-menu {
    font-size: 19px;
    line-height: 29px
}

.page-header .secondary-menu li {
    margin-bottom: 29px
}

.page-header .main-menu li:last-child,
.page-header .secondary-menu li:last-child {
    margin-bottom: 0
}

.page-header .social-menu {
    font-size: 40px;
    line-height: 50px;
    margin-top: 75px
}

.page-header .social-menu li {
    display: inline;
    margin: 0 8px
}

.page-header nav .sep {
    background-color: #b5159e;
    display: block;
    height: 2px;
    line-height: 2px;
    margin: 50px auto 35px;
    width: 23%
}

.page-content {
    padding: 90px 12.5%
}

/*body.App .page-content,
body.single-product .page-content {
    padding: 0
}*/



.App .screen {
    height: 100%;
    overflow: hidden;
    max-height: 1026px;
    min-height: 80vh;
    position: relative;
    text-align: center
}

.App .screen.banners .slides {
    height: 100%;
    list-style-type: none
}

.App .screen.banners .slides .item {
    background-color: #fff;
    background-attachment: fixed;
    background-position: center 350px;
    background-repeat: no-repeat;
 /*   -moz-background-size: cover;
    -webkit-background-size: cover;*/
    background-size: 700px;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    transition: all 1s;
    top: 0;
    width: 100%;
    opacity:1;
}

.home.ios .screen.banners .slides .item {
    background-attachment: scroll
}

.App .screen.banners .slides .item.selected {
    opacity: 1
}

.App .screen.banners .nav {
    left: calc(50% - 48px);
    position: absolute;
    bottom: 30px
}

.App .screen.banners .nav .item {
    background-color: #fff;
    border-radius: 9px;
    cursor: pointer;
    float: left;
    height: 18px;
    margin: 0 7px;
    transition: all .5s;
    width: 18px
}

.App .screen.banners .nav .item.selected {
    background-color: #b5159e
}

.App .intro{
	padding-top:90px;
}
.page-content .intro {
    padding: 65px 25% 0;
    text-align: center
}

.intro.slide{
    padding: 30px 25% 30px;
    position:fixed;
}

.intro.slide h2{
    margin:0;
}

.App .page-content .intro img,
.page-template-page-newsletter .page-content img {
    display: block;
    width: 75%
}
#play {
    text-align: center;
}

#play img{
    max-width:500px;
	width:100%;		   
}

.App .page-content .intro img {
    margin: 90px auto 0
}

.page-template-page-newsletter .page-content img {
    margin: 0 auto
}

.App .section-title {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;
    height: 250px;
    overflow: hidden;
    line-height: 250px
}

.App .section-title div {
    display: inline-block;
    vertical-align: middle
}

.App .section-title h2 {
    margin-bottom: 0;
	font-family: 'Bebas Neue', cursive;
}

#characteristics {
    color: #b5159e;
    padding: 70px 25% 65px;
    text-align: center
}

#characteristics h2 {
    margin-bottom: 90px;
    font-size: 150px;
    line-height: 160px
}

#characteristics h2 span {
    display: block;
    font-family: 'Avenir LT Std';
    font-size: 49px;
    line-height: 59px
}

#flavours .item,
.page-footer h3 {
    font-family: 'Bebas Neue', cursive;
}

#characteristics ul {
    margin: 0 auto
}

#characteristics .item {
    height: auto;
    float: left;
    font-size: 20px;
    line-height: 30px;
    padding: 0 2%;
    margin-bottom: 35px;
    text-transform: uppercase;
    width: 16.66%
}

#characteristics .item .icon {
    background-color: #fff;
    border-radius: 50%;
    height: 100%;
    margin-bottom: 20px;
    width: 100%
}

#characteristics .item .icon img,
#characteristics .item .icon svg {
    height: 100%;
    display: block;
    max-width: 100%
}

#characteristics .item svg path,
#characteristics .item svg polygon,
#characteristics .item svg text {
    fill: #b5159e
}

#flavours {
    padding: 90px 0 0
}

#flavours ul {
    margin: 0 auto
}

#flavours .item {
    height: auto;
    float: left;
    width: 16.6666%;
    display: block;
    font-size: 34px;
    line-height: 44px;
    padding: 0 1%;
    text-align: center;
    margin-bottom: 70px
}
#flavours .item2 {
    height: auto;
    float: left;
    width: 25%;
    display: block;
    font-size: 34px;
    line-height: 44px;
    padding: 0 1%;
    text-align: center;
    margin-bottom: 70px
}
#flavours .itemPack {
  
    display: flex;
    width: calc(12.5% - 1em);
    margin: .5em;
    padding: 0;
}
#flavours .itemPack img{
  
    display: flex;
    align-self: center;
}
#flavours .pack {
    padding: .25em;
    box-shadow: 0px 0px 7px 0px #ccc;
    border-radius: 10px;
	margin: 10px 0;
    background-color: rgba(181, 21, 158, .2);
}
.packContainer {
    display: flex;
    flex-wrap: wrap;
}
.packText{

    padding-left: 20px;
}
.rare{
    box-shadow:
    0 0 10px 5px #fff,  /* inner white */
    0 0 15px 10px #f0f, /* middle magenta */
    0 0 25px 15px #0ff; /* outer cyan */
    border-radius: 5%/4%;
}

@media(min-width:1025px) {
    #flavours .item:nth-child(6n+1) {
        clear: both
    }
    #flavours .item.m-75 {
        margin-left: 12.5%
    }
    #flavours .item.m-50 {
        margin-left: 25%
    }
    #flavours .item.m-25 {
        margin-left: 37.5%
    }
}

@media(min-width:721px) and (max-width:1024px) {
    #flavours .item:nth-child(3n+1) {
        clear: both
    }
    #flavours .itemPack {
  
        display: flex;
        width: calc(25% - 1em);
        margin: .5em;
        padding: 0;
    }
}

#flavours .item img {
    display: block;
    margin: 0 auto 20px;
    width: 100%;
}

#flavours .item a {
    transition: all .5s;
	font-family: 'Bebas Neue', cursive;
}

#flavours .item a:hover img {
    margin-top: -10px;
    margin-bottom: 30px
}

#flavours .cta {
    float: none;
    text-align: center;
    width: 50%;
    margin: 0 auto 40px
}

#retailers {
    color: #b5159e;
    padding: 90px 12.5% 55px;
    text-align: center
}

#retailers ul {
    margin: 0 auto
}

#retailers .item {
    height: 0;
    line-height: 0;
    float: left;
    margin: 0 2% 35px;
    width: 21%;
    text-align: center;
    border-radius: 50%
}
.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}
.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

@media(min-width:992px) {
	.tokens .col-sm-4 {
    width: 50%;
}
}
@media(min-width:721px) {
    #characteristics .item:nth-child(6n+1),
    #retailers .item:nth-child(4n+1) {
        clear: both
    }
    #retailers .item.m-75 {
        margin-left: 14.5%
    }
    #retailers .item.m-50 {
        margin-left: 27%
    }
    #retailers .item.m-25 {
        margin-left: 39.5%
    }
}

@media(max-width:767px) {
	.box input{
		padding: 5px 17px!important;
	}
	#tokens .col-sm-4 {
    padding: 0;
    
}
}
.page-content {
    padding: 90px 20px;
}
#flavours h1 {
    font-size: 21px;
	line-height: 25px;
}
@media(max-width:720px) {
    #characteristics .item:nth-child(3n+1),
    #flavours .item:nth-child(2n+1),
    #retailers .item:nth-child(2n+1) {
        clear: both
    }
    #flavours .itemPack {
  
        display: flex;
        width: calc(50% - 1em);
        margin: .5em;
        padding: 0;
    }
}

#retailers .item img {
    max-width: 75%;
    max-height: 75%;
    width: 100%;
    display: inline-block;
    vertical-align: middle
}

#retailers .retailers-msg {
    width: 50%;
    margin: 55px auto 0
}

#retailers .retailers-form {
    width: 37.5%;
    margin: 44px auto 0
}

#retailers .retailers-form input {
    height: 48px;
    margin-bottom: 0;
    width: calc(100% - 140px)
}

#retailers .retailers-form .btn.submit {
    border-radius: 16px;
    float: right;
    height: 48px;
    line-height: 48px;
    padding: 0;
    width: 110px
}

.App .screen.instagram ul {
    height: 100%
}

.App .screen.instagram .item {
    float: left;
    height: 50%;
    overflow: hidden;
    width: 25%
}

.App .screen.instagram .item img {
    display: block;
    height: auto;
    min-height: 100%;
    width: 100%
}

.App .screen.instagram .item img.landscape {
    height: 100%;
    min-width: 100%;
    width: auto
}

.App .screen.instagram .hover-card {
    background-color: rgba(255, 255, 255, .85);
    color: #b5159e;
    display: block;
    height: 50%;
    left: 0;
    padding: 33px 2% 0;
    position: absolute;
    top: 0;
    width: 25%
}

.App .screen.instagram .hover-card h3 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0;
    margin-top: 25px
}

.App .screen.instagram .hover-card .fa {
    font-size: 70px;
    line-height: 70px
}

.page-template-archive-product .page-content {
    padding-left: 0;
    padding-right: 0
}

.single-product .page-header {
    padding-bottom: 180px
}

.single-product .page-content {
    text-align: center
}

.single-product .product-ball {
    position: fixed
}

.single-product .product-ball.ball-1 {
    left: -2.5%;
    transform: rotate(-75deg);
    top: 205px;
    width: 10%
}

.single-product .product-ball.ball-2 {
    left: 3%;
    top: 380px;
    width: 13%
}

.single-product .product-ball.ball-3 {
    right: 15%;
    transform: rotate(50deg);
    top: 190px;
    width: 8%
}

.single-product .product-pint {
    display: block;
    margin: -100px auto 0;
    position: relative;
    width: 20%
}

.single-product .product-ingredients,
.single-product .product-nf {
    margin: 90px auto 0;
    width: 40%
}

.single-product .product-nf table {
    color: #aaa;
    width: 100%
}

.single-product .product-nf tr:nth-child(even) {
    background-color: #eee
}

.single-product .product-nf th {
    font-weight: 700;
    height: 43px;
    text-transform: uppercase
}

.single-product .product-nf td {
    font-size: 20px;
    height: 40px;
    line-height: 30px;
    padding: 0 20px;
    text-align: right;
    width: 90px
}

.single-product .product-nf td.nf-label {
    text-align: left;
    width: auto
}

.single-product .product-nf td.nf-legend {
    font-size: 18px;
    font-style: italic;
    height: 38px;
    line-height: 28px
}

.single-product .product-flavours {
    background-color: #eee;
    margin-top: 60px;
    position: relative
}

.single-product .product-flavours ul {
    padding: 60px 0
}

.single-product .product-flavours .fa {
    cursor: pointer;
    font-size: 50px;
    line-height: 50px;
    position: absolute;
    top: calc(50% - 25px)
}

.single-product .product-flavours .fa-chevron-left {
    left: 6%
}

.single-product .product-flavours .fa-chevron-right {
    right: 6%
}

.single-product .product-flavours .slider {
    margin: 0 auto;
    overflow: hidden;
    width: 75%
}

.single-product .product-flavours .slider ul {
    left: 0;
    position: relative;
    width: 0
}

.single-product .product-flavours .item {
    display: block;
    float: left;
    font-family: 'Nexa Script';;
    font-size: 29px;
    line-height: 39px;
    padding: 0 25px;
    width: 0
}

.single-product .product-flavours .item img {
    display: block;
    margin: 0 auto 10px;
    width: 60%
}

.single-product .product-flavours .item a {
    text-decoration: none;
    transition: all .5s
}




.page-footer {
    background-color: #b5159e;
    color: #fff;
    position: relative
}

.page-footer h3 {
    font-size: 39px;
    line-height: 49px;
    font-weight: 400;
    margin-bottom: 30px
}

.page-footer a {
    color: inherit
}

.page-footer .align-right,
.page-footer .newsletter-form {
    float: left;
    width: 50%
}

.page-footer .newsletter-form {
    padding: 80px 0 80px 12.5%
}

.page-footer .newsletter-form input {
    height: 48px;
    margin-bottom: 0;
    width: calc(100% - 140px)
}

.page-footer .newsletter-form .btn.submit {
    border-radius: 16px;
    float: right;
    height: 48px;
    line-height: 48px;
    padding: 0;
    width: 110px
}

.page-footer .newsletter-form .output {
    margin-top: 15px
}

.page-footer .align-right {
    padding: 80px 12.5% 80px 0;
    text-align: right
}

.page-footer .social-menu {
    font-size: 50px;
    line-height: 60px
}

.page-footer .social-menu li {
    display: inline;
    margin: 0 14px
}

.page-footer nav {
    background-color: #fff;
    clear: both;
    color: #b5159e;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    padding: 0 3%
}

.page-footer nav a {
    text-decoration: none
}

.page-footer nav a:hover {
    text-decoration: underline
}

.page-footer .secondary-menu li {
    display: inline;
    float: right;
    margin-right: 40px
}

.page-footer .secondary-menu li:first-child {
    margin-right: 0
}

.page-footer .secondary-menu li.copy {
    float: left
}

#iecomp-modal {
    background: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    clear: both
}

#iecomp-modal div.iecomp-box {
    background-color: #b5159e;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 19px;
    margin: 100px auto 0;
    padding: 25px;
    text-align: center;
    width: 600px
}

#iecomp-modal span.iecomp-desc,
#iecomp-modal span.iecomp-sol {
    margin-bottom: 25px;
    display: block
}

#iecomp-modal span.iecomp-title {
    display: block;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px
}

#iecomp-modal span.iecomp-desc span {
    font-weight: 700
}

#iecomp-modal span.iecomp-sol {
    border-bottom: 1px solid #fff;
    padding-bottom: 25px
}

#iecomp-modal div.iecomp-links a {
    background-color: transparent;
    background-position: left center;
    background-repeat: no-repeat;
    color: #fff;
    display: block;
    float: left;
    height: 30px;
    margin-right: 25px;
    padding-left: 40px;
    padding-top: 5px;
    text-align: left;
    text-decoration: underline;
    width: 165px
}
#flavours .tablink{
	font-family: 'Bebas Neue', cursive;
	font-size: 24px;
	padding: 0 12px;
	color: #b5159e;
	background: transparent;
	cursor: pointer;
	margin: 0 20px;
}
.App .tab{
	margin: 0 0 30px;
}
.tab {
    text-align: center;
	margin-bottom: 65px;
}

.App #flavours .tab{
margin-bottom: 65px;	
}
#flavours .tablink.active {
    border-bottom: 3px solid;	
}
.App #flavours {
    padding-left: 12%;
    padding-right: 12%;
	padding-top: 65px;
	margin-top:65px;
}
.tab.buypack {
    margin: 0;
}
.tabcontent h1 {
    margin: 7px 0 37px;
}
.social-menu:last-child img {
    position: relative;
    top: 7px !important;
    
}
.foot-discord{
    width:35px;
}

.App .section-title{
	background: #b5159e;
}

.nexa{
	font-size: 18px;
    line-height: 50px;
    font-family: 'Bebas Neue', cursive;
    margin-top: -22px;
    padding-top: 0;
    font-weight: normal;
	width:100%;		   
}

#flavours {
    padding-top: 0 !important;
}
#flavours h1 {
    font-size: 27px;
    text-align: center;
    padding: 0 0 17px;
	font-family: 'Bebas Neue', cursive;
	color: #000;
	font-weight: normal;
}
.tabcontent img{
	width: 100%;
}
.box {
    box-shadow: 0px 0px 7px 0px #ccc;
    border-radius: 16px;
    text-align: center;
	padding-bottom: 1px;
	margin: 0 10px;
}
.box input {
    background: #eaeaea;
    width: 100%;
    padding: 17px 17px;
}
.box button, .intro a{
	background: #b5159e;
color: #fff;
padding: 15px 32px;
font-size: 17px;
margin: 20px 0;
border-radius: 35px;
cursor: pointer;
text-decoration: none;
}
.box p{
	margin-bottom: 10px;
}
.box p span {
    float: left;
    width: 100%;
}
#characteristics img {
    max-width: 570px;
	width:100%;			
}
@media(max-width:1920px){
	.page-header .social-menu li {
    margin: 0 17px;
	}
	.page-header .logo img{
		height: 87px;
width: auto;
	}
	.page-header .social-menu{
		margin-top: 21px!important;
	}
	.social-menu:last-child img {
    top: -4px;
}
}
@media(max-width:1280px),
(max-height:720px) {
    body {
        font-size: 20px;
        line-height: 30px
    }
    h1 {
        font-size: 55px;
        line-height: 50px
    }
    h2 {
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 40px
    }
    h3 {
        font-size: 29px;
        line-height: 34px;
        margin-bottom: 18px
    }
    p {
        margin-bottom: 30px
    }
    .custom-select select,
    .output,
    input[type=text],
    input[type=email],
    textarea {
        font-size: 14px
    }
    .custom-select,
    input[type=text],
    input[type=email] {
        height: 45px
    }
    .custom-select,
    input[type=text],
    input[type=email],
    textarea {
        margin-bottom: 29px
    }
    textarea {
        height: 115px
    }
    .output {
        line-height: 24px
    }
    .btn {
        border-radius: 14px;
        font-size: 20px;
        height: 42px;
        line-height: 42px
    }
	.page-header {
        padding-bottom: 25px;
		padding-top: 25px;
		margin: 0;
    }
    .page-header .intro {
        margin-top: 39px
    }
	.page-header .logo img{
		top: 12px;
	}
    .page-header .menu-opener {
        top: 43px
    }
    .page-header .menu-opener {
        font-size: 17px;
        height: 36px;
        line-height: 17px;
        padding-top: 9px;
        width: 36px
    }
    .page-header .language-switcher {
        font-size: 14px
    }
    .page-header .main-menu {
        font-size: 25px;
        line-height: 35px;
        margin-top: 20px
    }
    .page-header .main-menu li {
        margin-bottom: 22px
    }
    .page-header .secondary-menu {
        font-size: 14px;
        line-height: 24px
    }
    .page-header .secondary-menu li {
        margin-bottom: 24px
    }
    .page-header .social-menu {
        font-size: 29px;
        line-height: 39px;
        margin-top: 54px
    }
    .page-header nav .sep {
        margin: 36px auto 25px
    }
    .page-content {
        padding-bottom: 65px;
        padding-top: 65px
    }
    .page-footer .align-right,
    .page-footer .newsletter-form {
        padding-bottom: 20px;
        padding-top: 15px;
    }
    .page-footer h3 {
        font-size: 25px;
line-height: 35px;
margin-bottom: 15px;
    }
    .page-footer .newsletter-form input {
        height: 35px
    }
    .page-footer .newsletter-form .btn.submit {
        border-radius: 12px;
        height: 35px;
        line-height: 35px
    }
    .page-footer .newsletter-form .output {
        margin-top: 11px
    }
    .page-footer .align-right {
        text-align: right
    }
    #flavours,
    #retailers,
    .App .page-content .intro {
        padding-top: 30px
    }
    .page-footer .social-menu {
        font-size: 30px;
        line-height: 40px
    }
    .page-footer nav {
        font-size: 12px;
        height: 36px;
        line-height: 36px
    }
    .page-footer .secondary-menu li {
        margin-right: 29px
    }
    .App .page-content .intro img {
        margin-top: 65px
    }
    .App .section-title {
        height: 180px;
        line-height: 180px
    }
    #characteristics {
        padding-bottom: 47px;
        padding-top: 50px
    }
    #characteristics h2 {
        margin-bottom: 65px;
        font-size: 100px;
        line-height: 118px
    }
    #characteristics h2 span {
        font-size: 35px;
        line-height: 45px
    }
    #characteristics .item {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 25px;
		font-family: 'Avenir LT Std';
    }
    #characteristics .item .icon {
        margin-bottom: 14px
    }
    #flavours .item {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 50px
    }
    #flavours .item img {
        margin-bottom: 14px
    }
    #flavours .item a:hover img {
        margin-bottom: 24px
    }
    #retailers {
        padding-bottom: 40px
    }
    #retailers .item {
        margin-bottom: 25px;
		border: 1px solid;
    }
    #retailers .retailers-msg {
        margin-top: 40px
    }
    #retailers .retailers-form {
        margin-top: 32px
    }
    #retailers .retailers-form input {
        height: 35px
    }
    #retailers .retailers-form .btn.submit {
        border-radius: 12px;
        height: 35px;
        line-height: 35px
    }
    .App .screen.instagram .hover-card {
        padding-top: 24px
    }
    .App .screen.instagram .hover-card h3 {
        font-size: 22px;
        line-height: 32px;
        margin-top: 18px
    }
    .App .screen.instagram .hover-card .fa {
        font-size: 50px;
        line-height: 50px
    }
}

@media(max-width:1024px) {
    #flavours .item,
    .App .screen.instagram .hover-card,
    .App .screen.instagram .item {
        width: 33.33%
    }
    #characteristics,
    .App .page-content .intro,
    .page-header {
        padding-left: 12.5%;
        padding-right: 12.5%
    }
    #retailers .retailers-form {
        width: 75%;
        left: 12.5%
    }
    #retailers .retailers-msg {
        width: 100%
    }
    .single-product .page-header {
        padding-bottom: 81px
    }
    .single-product .product-ball.ball-1 {
        top: 109px
    }
    .single-product .product-ball.ball-2 {
        top: 201px
    }
    .single-product .product-ball.ball-3 {
        top: 101px
    }
    .single-product .product-pint {
        margin-top: -45px
    }
}

@media(max-width:860px),
(orientation:portrait) {
    body {
        font-size: 15px;
        line-height: 25px
    }
    h1 {
        font-size: 40px;
        line-height: 45px
    }
    h2 {
        font-size: 26px;
        line-height: 31px
    }
    h3 {
        font-size: 21px;
        line-height: 26px
    }
    p {
        margin-bottom: 25px
    }
    .page-header {
        padding-bottom: 44px;
        padding-top: 75px
    }
    .page-header .intro {
        margin-top: 26px
    }
    .page-header .logo img,
    .page-header .menu-opener {
        top: 29px
    }
    .page-header .language-switcher,
    .page-header .menu-closer {
        top: 32px
    }
    .page-content {
        padding-bottom: 44px;
        padding-top: 44px
    }
    .page-footer .align-right,
    .page-footer .newsletter-form {
        padding-bottom: 39px;
        padding-top: 39px
    }
    body.App .page-content,
    body.single-product .page-content {
        padding: 0
    }
    .App .screen.instagram .hover-card,
    .App .screen.instagram .item {
        width: 50%
    }
    #retailers .retailers-msg {
        width: 87%
    }
    #characteristics h2 {
        margin-bottom: 44px;
        font-size: 72px;
        line-height: 82px
    }
    #characteristics h2 span {
        font-size: 23px;
        line-height: 33px
    }
    .single-product .product-ingredients,
    .single-product .product-nf {
        width: 75%
    }
    #flavours .cta,
    .page-template-page-store .cta {
        width: 100%
    }
}

@media(max-width:720px),
(orientation:portrait) {
    .page-footer .secondary-menu .menu-item,
    .single-product .product-ball {
        display: none
    }
    .page-header .menu-closer {
        right: 10%
    }
    .page-header .language-switcher {
        right: calc(10% + 38px)
    }
    .page-header .logo img {
        height: 36px;
        width: auto
    }
    .page-footer .newsletter-form input,
    .page-header nav {
        width: 100%
    }
    .page-footer .newsletter-form .btn.submit {
        float: left;
        margin-top: 20px
    }
    .page-footer .align-right,
    .page-footer .newsletter-form {
        padding-left: 12.5%;
        width: 88%
    }
    .page-footer .align-right {
        padding-top: 0;
        text-align: left
    }
    #characteristics .item {
        width: 33.33%
    }
    #flavours .item {
        width: 50%
    }
    #retailers .item {
        width: 46%
    }
    #retailers .retailers-form {
        position: relative;
        width: 100%;
        left: 0;
        margin: 50px 0
    }
    #retailers .retailers-form input {
        width: 100%;
        text-transform: uppercase
    }
    #retailers .retailers-form .btn.submit {
        float: left;
        margin-top: 20px
    }
}
@media(max-width:480px) {
	.d-flex {
    display: inline !important;
}
}

.page-header .logo img {
    height: 115px;
    width: auto;
}

.page-header .logo img {
    top: 12px;
}

.page-header .menu-opener {
    top: 43px;
}

.unlockbutton {
    margin: 18% auto 5%;
    width:150px;
}

.hmeMXj {
    -moz-box-align: center;
    align-items: center;
    background: #b5159e;
    border: 0px none;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    height: 40px;
    -moz-box-pack: center;
    justify-content: center;
    outline: currentcolor none medium;
    opacity: 1;
    padding-left: 16px;
    padding-right: 16px;
width:100%;
font-family:'Bebas Neue', cursive;
    
}

.text-right{
    text-align:right;
}

.mr-5.ml-5 {
    width: 80%;
    margin: auto;
}

.intro.hometxt h2 {
    margin-bottom: 65px;
    font-size: 100px;
    line-height: 118px;
    color:#b5159e;
}

.tabcon{
    display:none;
}

.tabcon.active{
    display:block;
}

.headchara {
    width: 700px;
    margin-top: -20px;
}  

.intro.slide p {
    margin: 15px;
    line-height: 22px;
    font-size: 18px;
}

.homecontent{
    background: #fff;
    display: block;
    position: relative;
    z-index: 1;
}

img {
    vertical-align: initial;
}

.page-header .logo img {
    top: 22px;
    left: 40px;
}

@media(max-width:767px) {
	.page-header .logo img {
    top: 0;
    left: 0;
    width: 40%;
    height: auto;
}

.headchara {
    
    margin-top: -10px;
    width: 80%;
	
}

.page-header .menu-opener {
    top: 15px;
}

.page-header{
	padding-left:0;
	padding-right:0;
}

#flavours .cta {
    float: none;
    text-align: center;
    width: 50%;
    margin: 0 auto 0px;
	padding-bottom:40px;
}

.page-footer .align-right, .page-footer .newsletter-form {
    padding-left: 0;
    width: 85%;
    padding-right: 0;
    float: none;
    margin: auto;
	text-align: center;
}

.page-footer .align-right {
    padding-top: 0;
    text-align: center;
	width:100%;
}

.page-footer .newsletter-form .btn.submit {
    float: none;
}

.intro.slide{
	position:relative;
}

.App .screen.banners .slides .item {
    background-color: #fff;
    background-attachment: initial;
    background-position: center 350px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    transition: all 1s;
    top: 0;
    width: 100%;
    opacity: 1;
}

#flavours .tablink {
    font-family: 'Bebas Neue', cursive;
    font-size: 18px;
    padding: 0;
    color: #b5159e;
    background: transparent;
    cursor: pointer;
    margin: 0 15px;
}


.App #flavours {
    padding-left: 5%;
    padding-right: 5%;
}	

.menu-closer .fa{
	font-size:25px;
}

.App .screen{
	
	min-height:auto !important;
}

}
input::-webkit-input-placeholder { /* WebKit browsers */
    color:    rgba(181, 21, 158,.5)
}
input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    rgba(181, 21, 158,.5)
}
input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    rgba(181, 21, 158,.5)
}
input:-ms-input-placeholder { /* Internet Explorer 10+ */
    color:    rgba(181, 21, 158,.5)
}